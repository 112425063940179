import { ChangeEvent } from 'react';

import { PaymentMethod } from '@tgg/common-types';

import { AlertMessage } from '../../AlertMessage';
import { Asterisk } from '../../Asterisk';
import { DirectDebitInstructionOverlay } from '../../DirectDebitInstructionOverlay';
import { Tickbox } from '../../Tickbox';
import { StyledHorizontalRule, StyledLink } from '../CardPayment.styled';

import {
    StyledHeading,
    StyledTickBoxesWrapper,
} from './MembershipAgreement.styled';
import { MembershipAgreementProperties } from './MembershipAgreement.types';
import { useMembershipAgreement } from './utils';

export function MembershipAgreement({
    selectedPaymentMethod,
    expectedPaymentFrequency,
    wref,
    accountData,
    agreementTypes,
    onTermsAndConditionsAccepted,
}: MembershipAgreementProperties) {
    const { agreementCheckBoxes, setAgreementCheckBoxes } =
        useMembershipAgreement({
            agreementTypes,
            selectedPaymentMethod,
            onTermsAndConditionsAccepted,
        });

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const stateValue = {
            ...agreementCheckBoxes,
            [name]: checked,
        };

        setAgreementCheckBoxes(stateValue);
    };

    const sectionTitle = agreementTypes.includes('termsAndConditions')
        ? 'Membership Agreement'
        : 'Payment Agreement';

    const showDDInstructions =
        accountData && expectedPaymentFrequency === 'Monthly' && wref;

    return (
        <>
            {selectedPaymentMethod === PaymentMethod.Paypal && (
                <AlertMessage type="information">
                    You will be redirected to the PayPal page when you click on
                    the <b>&apos;PayPal&apos;</b> button below.
                </AlertMessage>
            )}
            <StyledHorizontalRule />
            <StyledHeading variant="h2" component="h3" gutterBottom={false}>
                {sectionTitle}
            </StyledHeading>
            <StyledTickBoxesWrapper>
                {showDDInstructions && (
                    <AlertMessage type="information">
                        If you want to see a copy of your Direct Debit
                        instruction,{' '}
                        <DirectDebitInstructionOverlay
                            linkText="click here"
                            accountData={accountData}
                            paymentReference={wref}
                        />
                        .
                    </AlertMessage>
                )}
                {agreementTypes.includes('cardHolder') && (
                    <Tickbox
                        name="cardHolder"
                        checked={agreementCheckBoxes.cardHolder}
                        onChange={handleCheckboxChange}
                    >
                        I can confirm I am the authorised account holder and can
                        authorise payments from this account
                        <Asterisk spaceBefore />
                    </Tickbox>
                )}
                {agreementTypes.length === 2 && <br />}
                {agreementTypes.includes('termsAndConditions') && (
                    <Tickbox
                        name="termsAndConditions"
                        checked={agreementCheckBoxes.termsAndConditions}
                        onChange={handleCheckboxChange}
                    >
                        I confirm that I have read and agree to be bound by the
                        terms of the{' '}
                        <StyledLink
                            href="https://www.thegymgroup.com/legal/membership-agreement/"
                            target="_blank"
                        >
                            Terms & Conditions
                        </StyledLink>{' '}
                        and{' '}
                        <StyledLink
                            href="https://www.thegymgroup.com/globalassets/documents/memberhealthagreement.pdf"
                            target="_blank"
                        >
                            The Gym Member Health Agreement (PDF)
                            <Asterisk spaceBefore />
                        </StyledLink>
                    </Tickbox>
                )}
            </StyledTickBoxesWrapper>
        </>
    );
}
